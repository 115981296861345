import React, { useEffect, useState }             from "react";
import { useDispatch, useSelector }               from "react-redux";
import { useParams, useNavigate }                 from "react-router-dom";
import Moment                                     from "react-moment";
import                                                 "moment/locale/es";
import session                                    from "../utils/session";

import { getInfoPatient, updatePatient }    from "../redux/actions/patients";
import { setBackButton, setShowFooterMenu } from "../redux/actions/layout";
import { getPrescriptions }                 from "../redux/actions/prescriptions";

import { useAuth }          from "../components/AuthProvider";
import { usePrescriotion }  from "../components/PrescriptionProvider";
import diff                 from "../components/diff";
import PatientForm          from "../components/PatientForm";
import MedicalHistory       from "../components/MedicalHistory";
import PatientHeader        from "../components/PatientHeader";
import ProfilePatient       from "../components/ProfilePatient";
import SectionLoading       from "../components/SectionLoading";
import SectionError         from "../components/SectionError";
import Prescription         from "../components/Prescription";

import removeAreaCode from '../middlewares/removeAreaCode';

import { toast } from 'react-toastify';

const InfoPatient = () => {
  const { id, section: sectionParams } = useParams();

  const section = sectionParams || "info"; // TODO:: parche

  const navigate                = useNavigate();
  const { setUrl }              = useAuth();
  const { handleSelectPatient } = usePrescriotion();

  let Storage = null;
  if (localStorage?.CurrentUrl) {
    Storage = JSON.parse(localStorage.CurrentUrl);
  }

  const dispatch = useDispatch();

  const error               = useSelector((state) => state.patients.error);
  const loading             = useSelector((state) => state.patients.isLoading);
  const patient             = useSelector((state) => state.patients.patient);
  const updateOk            = useSelector((state) => state.patients.update);
  const inChangePatientTags = useSelector(state => state.patients.inChangePatientTags);
  
  const [selectedDate, setSelectedDate]                   = useState(new Date()); /*2014-08-18T21:11:54*/
  const [userId, setUserId]                               = useState(null);
  const [headerSmall, setHeaderSmall]                     = useState(false);
  const [open, setOpen]                                   = useState(false);
  const [patientUpdate, setPatientUpdate]                 = useState({});
  const [medicalHistory, setMedicalHistory]               = useState(false);
  const [medicalHistoryDetails, setMedicalHistoryDetails] = useState(null);
  const [errorMessage, setErrorMessage]                   = useState(null);
  const [editMode, setEditmode]                           = useState(false);
  const [patientTags, setPatientTags]                     = useState([]);
  const [phoneCode, setPhoneCode]                         = useState();
  const [cleanPhone, setCleanPhone]                       = useState();
  const [isSubmitting, setIsSubmitting]                   = useState(false);
  const [firstCloseMenu, setFirstCloseMenu]               = useState(true);
  const [openMenu, setOpenMenu]                           = useState(false);
  const [requiredFields, setRequiredFields]               = useState({
    surname: false,
    name: false,
    telephone: false,
    office_id: false,
  });
  const [ hasAudioTranscription , setHasAudioTranscription ] = useState(false);  
  const [ existsAddons, setExistsAddons ]      = useState(true);

  const [ loadingExternalEditMode, setLoadingExternalEditMode ] = useState(true);

  const { offices, professional: professionalData, secretary: secretaryData, country_id } = session.getUser();
  const userData = session.getUser();

  const currentUrl = useParams();

  useEffect(() => {
    console.log('currentUrl', Storage);
    console.log(currentUrl);
    if (Storage?.fromAppointmet) {
      setUrl({...Storage, id: currentUrl, fromAppointmet: true});
    } else {
      setUrl({id: currentUrl ,fromAppointmet: false});
    }   

  }, [currentUrl]);
  
  useEffect(() => {

    if (patientUpdate && phoneCode && cleanPhone) {
      const phone = "" + phoneCode + cleanPhone;
      if (phone != patientUpdate.telephone) {
        console.log('patientUpdate ------>>>>>>',  cleanPhone);
        console.log('patientUpdate ------>>>>>>',  phoneCode);
        // phone = removeAreaCode(cleanPhone, String(phoneCode));
        setPatientUpdate({
          ...patientUpdate,
          telephone: phone,
        });
      }
    }    

  }, [cleanPhone]);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  }

  useEffect(() => {
    if (professionalData) {
      setUserId(professionalData.id)
    } else if (secretaryData) {
      setUserId(secretaryData.id)
    }
    dispatch(getInfoPatient({ id }));
    dispatch(setBackButton(true));
    dispatch(setShowFooterMenu(false));
    setIsSubmitting(false);
    // getData(1, '', true);

    setTimeout(() => {
      setFirstCloseMenu(false);      
    }, 1000);

    console.log('123 Storage', Storage);
    if (Storage.type == 'create-pat') {
      setTimeout(() => {
        setLoadingExternalEditMode(false);
        editPatient();
      }, [100]);      
    } else {
      setLoadingExternalEditMode(false);
    }


  }, []);

  useEffect(() => {
    if (section) {
      setHeaderSmall(section != 'info');
    }    
  }, [section]);

  useEffect(() => {
    if (patient) {
      
      setPatientUpdate({ ...patient });
      
      if (patient.born_date) {
        setSelectedDate(returnDate(patient.born_date));
      }

      refreshTagIds();
      hasAddon()
    } else {
      if (error) {
        const errDetail = error.response?.data?.message;
        if (errDetail) {
          setErrorMessage(errDetail);
        } else {
          setErrorMessage('Error al obtener los datos del paciente.');
        }
      }
    }
  }, [patient]);

  useEffect(() => {
    if (!inChangePatientTags) {
      refreshTagIds();
    }
  }, [inChangePatientTags]);

  
  useEffect(() => {
    setPatientUpdate({
      ...patientUpdate,
      born_date: selectedDate,
    });
  }, [selectedDate]);

  const hasAddon = () => {
    // console.log("user", userData)
    // console.log("offices", offices)

    if (userData?.addons?.ia == 1) {
      setHasAudioTranscription(true)
    };

  }

  const handleDateChange = (date, isValid) => {
    setSelectedDate(date);
  };

  const handleChange = (e) => {
    setPatientUpdate({
      ...patientUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePhone = (e, code) => {

    console.log('handleChangePhone', code);

    setPhoneCode(code);
    setCleanPhone(removeAreaCode(e.target.value, String(code)));   

    if (e.target.value === "") {
      setRequiredFields({ ...requiredFields, [e.target.name]: true });
    } else {
      setRequiredFields({ ...requiredFields, [e.target.name]: false });
    }    

  }

  const validateForm = (e = null) => {
    if (e) {
      e.preventDefault();
    }

    setIsSubmitting(true);

    console.log(patient);
    console.log(patientUpdate);
    let newData = diff(patient, patientUpdate);

    // Dejo solo lo que es necesario actualizar
    delete newData.lastest_5_events;
    delete newData.next_5_events;
    delete newData.offices;
    delete newData.prescriptions;
    delete newData.tags;
    delete newData.events_count;

    const Data = {
      newData: newData,
      patient_id: id,
      userId,
    };

    dispatch(updatePatient(Data));

    if (Storage?.fromAppointmet && Storage?.type == 'create-pat') {
      setLoadingExternalEditMode(true);
      setTimeout(() => {
        setUrl(null);
        navigate("/appointments");
      }, [1000]);
    }

  };

  useEffect(() => {
    if(updateOk) {
      handleOpen(open);
      setEditmode(false);
      dispatch(getInfoPatient({ id, userId }));
    } else if(updateOk == false) {
      handleOpen(open);
    }
  }, [loading]);


  const refreshTagIds = () => {
    let tagIds = [];
    if (patient?.tags) {
      tagIds = patient.tags.map(a => a.tag_id);
    }
    setPatientTags(tagIds);
  }

  const returnDate = (date) => {
    let year = parseInt(date.slice(0, 4));
    let month = parseInt(date.slice(5, 7));
    let day = parseInt(date.slice(8, 10));
    month = month - 1;

    return new Date(year, month, day);
  };

  // const getData = (pageNumber = 1, newName = "", reset = false) => {
  //   let Data = {
  //     name: newName,
  //     userId,
  //     limit: 50,
  //     pageNumber,
  //     reset
  //   };
  //   dispatch(getPrescriptions(Data));
  // };

  const handleClickSection = (section) => {
    // TODO::: no reemplazaba el último parametro
    // navigate(section)
    navigate(`/patients/${id}/${section}`);
    // if (section == 'prescriptions') {
    //   getData();
    // }
    setOpenMenu(false);
  }

  const handleClickNewPrescription = (id) => {
    handleSelectPatient(patient);
    navigate(`/prescriptions/${id}/new`);
  }

  const handleClickNewAppointment = (id) => {
    navigate(`/appointments/new`);
  }

  const handleClickNewConsultation = (id) => {
    const objB64 = btoa(JSON.stringify(
      {
        name: `${patient.surname}, ${patient.name}`
      }
    ));
    navigate(`/patients/${id}/new-consultation/${objB64}`);
  }

  const handleOpen = () => {
    if (error) return;
    setOpen(true);
  };

  const handleGoBack = () => {
    if (Storage?.fromAppointmet) {
      navigate("/appointments")      
    } else {
      navigate("/patients")
    }    
  }

  const editPatient = () => {
    setEditmode(true);
    setPatientUpdate({...patient});
  };

  const handleCloseEdit = () => {
    setEditmode(false);
    dispatch(setShowFooterMenu(true));

    console.log('handleCloseEdit', Storage)
    if (Storage?.fromAppointmet && Storage?.type == 'create-pat') {      
      setUrl(null);
      navigate("/appointments");
    }
  };

  const findLastPrescription = (patient) => {
    const auxPres = [ ...patient.prescriptions ];
    const lastPrescription = auxPres[0];
    return lastPrescription;
  }

  const getEventText = (data) => {
    if (data) {
      return (
          <div style={{ border: 'none' }} className={ `bg-[${ data.color }] text-white mb-1 badge rounded-xl`}>
            { data.value }
          </div>
        )
    } else {
      return <div></div>
    }    
  }

  const getEventTextLastest_5 = (data) => {
    if (data) {
      return (
          <div style={{ border: 'none' }} className="bg-gray-400 text-white rounded-xl mb-1 badge">
            { data.value }
          </div>
        )
    } else {
      return <div></div>
    }    
  }

  const handleUploadImage = (id) => {
    navigate(`/patients/${id}/hc/upload-photo`);
  }

  const handleUploadAudio = (id) => {
    if (hasAudioTranscription) {
      navigate(`/patients/${id}/hc/upload-audio`);
    } else {
      toast.info("Debes tener activo el complemento de Transcripción de Audio para utilizar esta función");
    }
    
  }

  if (!errorMessage && (!patient || patient.id !== parseInt(id))) {
    return <SectionLoading loading={ true } />
  }
  if (loadingExternalEditMode) {
    return <SectionLoading loading={ true } />
  }

  if (errorMessage) {
    return (
      <SectionError
       message={ errorMessage }
       actionMessage="Volver al listado"
       actionLink="/patients"
      ></SectionError>
    )
  }

  return (
    <>
      { openMenu && (
        <div className="bg-white fixed opacity-80 w-full h-full"></div>
      )}
        

      {!editMode && !medicalHistory ? (
        <div className="py-2 h-screen bg-white px-2 overflow-x-auto">


        <div className="fixed top-0 left-0 right-0 z-20 bg-white pb-2">
          <div className="-m-2 mb-4">
            <PatientHeader patient={patient} headerSmall={headerSmall} />
          </div>          

          <nav className="flex justify-center font-sans text-lg space-x-2 mt-1">
            <button onClick={ () => handleClickSection('info')          } className={`font-sans font-semibold px-4 py-1.5 rounded-xl ${ section=='info' ? 'bg-[#3388FF] text-white' : 'text-zinc-400' }`}>Perfil</button>
            <button onClick={ () => handleClickSection('events')        } className={`font-sans font-semibold px-4 py-1.5 rounded-xl ${ section=='events' ? 'bg-[#3388FF] text-white' : 'text-zinc-400' }`}>Turnos</button>
            {(professionalData && !secretaryData) &&
              <>
              <button onClick={ () => handleClickSection('prescriptions') } className={`font-sans font-semibold px-4 py-1.5 rounded-xl ${ section=='prescriptions' ? 'bg-[#3388FF] text-white' : 'text-zinc-400' }`}>Recetas</button>
              <button onClick={ () => handleClickSection('hc')            } className={`font-sans font-semibold px-4 py-1.5 rounded-xl ${ section=='hc' ? 'bg-[#3388FF] text-white' : 'text-zinc-400' }`}>HC</button>
              </>
            }
          </nav>
        </div>

        <div className={`${ headerSmall ? 'pt-[180px]' : 'pt-[360px]'} z-0`}>

          
          <div className="fixed flex flex-col right-[30px] bottom-[20px] z-50">
            <div className={`${(firstCloseMenu) ? "hidden" : ""} flex flex-col justify-end mr-1`}>
              
              <div className={`${(openMenu) ? 'mb-[8px]' : 'invisible mb-[-45px]'} transition-all duration-500`}>
                <div div className="flex justify-end items-center">
                  { openMenu && (<div className="float-menu-label" onClick={() => handleClickNewAppointment(patient.id)}>Turno</div>)}
                  <button onClick={() => handleClickNewAppointment(patient.id)} className="float-menu-button">
                    <div className="pl-[13px]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 42 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line><line x1="30" y1="5" x2="30" y2="13"></line><line x1="26" y1="9" x2="34" y2="9"></line></svg>
                    </div>
                  </button>
                </div>
              </div>

              {(professionalData && !secretaryData) &&
              <>
                <div className={`${(openMenu) ? 'mb-[8px]' : 'invisible mb-[-45px]'} transition-all duration-500`}>
                  <div className="flex justify-end items-center">
                    { openMenu && (<div className="float-menu-label" onClick={() => handleClickNewPrescription(patient.id)}>Receta</div>)}
                    <button onClick={() => handleClickNewPrescription(patient.id)} className="float-menu-button">
                      <div className="pl-[13px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 36 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"></path>
                            <line x1="30" y1="5" x2="30" y2="13"></line><line x1="26" y1="9" x2="34" y2="9"></line>
                        </svg>
                      </div>
                    </button>
                  </div>                       
                </div>

                <div className={`${(openMenu) ? 'mb-[8px]' : 'invisible mb-[-45px]'} transition-all duration-500`}>
                  <div className="flex justify-end items-center">
                    { openMenu && (<div className="float-menu-label" onClick={() => handleClickNewConsultation(patient.id)}>Evolucionar</div>)}
                    <button onClick={() => handleClickNewConsultation(patient.id)} className="float-menu-button">
                      <div className="pl-[13px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-6 h-6" viewBox="0 0 16 16">
                          <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" fill="white"></path>
                        </svg>
                      </div>
                    </button>
                  </div>                            
                </div>
                
                <div className={`${(openMenu) ? 'mb-[8px]' : 'invisible mb-[-45px]'} transition-all duration-500`}>
                  <div className="flex justify-end items-center">
                    { openMenu && (<div className="float-menu-label" onClick={() => handleUploadImage(patient.id)}>Imagen</div>)}
                    <button onClick={() => handleUploadImage(patient.id)} className="float-menu-button">
                      <div className="pl-3 group-hover hover:text-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                        </svg>
                      </div>
                    </button>
                  </div>
                </div>

                {(existsAddons && hasAudioTranscription) && //en el if solo va existsAddons
                  <div className={`${(openMenu) ? 'mb-[8px]' : 'invisible mb-[-45px]'} transition-all duration-500`}>
                    <div className="flex justify-end items-center">
                      { openMenu && (<div className="float-menu-label" onClick={() => handleUploadAudio(patient.id)}>Audio</div>)}
                      <button onClick={() => handleUploadAudio(patient.id)} className={`rounded-full block ${hasAudioTranscription? 'hover:bg-white hover:text-[#3388FF] bg-[#3388FF] text-white' : 'hover:bg-gray-500 hover:text-white bg-gray-400 text-white'} text-lg w-12 h-12 z-50 ml-[2px] shadow-xl`}>
                        <div className={`${(hasAudioTranscription) ? 'hover:text-blue-500 pl-3 group-hover' : 'hover:text-white pl-3 group-hover'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                }
                
                
              </>
              }
            </div>
            <div className="flex justify-end">
              <button onClick={handleOpenMenu} className={`${(openMenu) ? 'rotate-[137deg] text-[#3388FF] bg-white' : 'bg-[#3388FF] text-white'}  block transition-all duration-500 rounded-full text-lg w-14 h-14 z-50 shadow-xl`}>
                <div className="">
                  <div className="text-2xl font-semibold">
                    +
                  </div>
                </div>
              </button>
            </div>
          </div>

          { /* SECCIONES */ }

          { /* INFO */ }
          { (section == 'info') && (

            <>
            
            <ProfilePatient patient={ patient}/>
            <button onClick={editPatient} className="absolute top-12 right-6 z-20">
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.90683 1.31686C10.5899 0.633761 11.5164 0.25 12.4825 0.25C14.4942 0.25 16.1249 1.8808 16.1249 3.89249C16.1249 4.85853 15.7412 5.78502 15.0581 6.46812L10.6517 10.8745C8.63989 12.8863 6.11919 14.3135 3.35907 15.0035L2.5074 15.2165C1.69274 15.4201 0.954828 14.6822 1.15849 13.8675L1.37141 13.0159C2.06144 10.2558 3.48864 7.73506 5.50041 5.72329L9.90683 1.31686ZM12.4825 1.75C11.9142 1.75 11.3693 1.97573 10.9675 2.37752L10.2683 3.0767C10.1445 3.7018 10.4536 4.52884 11.1498 5.22512C11.8461 5.92139 12.6731 6.23046 13.2983 6.10664L13.9974 5.40746C14.3992 5.00566 14.6249 4.46071 14.6249 3.89249C14.6249 2.70922 13.6657 1.75 12.4825 1.75ZM11.9466 7.45828C11.2391 7.22525 10.5906 6.78716 10.0892 6.28578C9.58779 5.78439 9.1497 5.13587 8.91668 4.42834L6.56107 6.78395C4.74154 8.60348 3.45072 10.8833 2.82662 13.3797L2.77041 13.6045L2.99526 13.5483C5.49164 12.9242 7.77147 11.6334 9.591 9.81388L11.9466 7.45828Z" fill="black"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M0 19C0 18.5858 0.335786 18.25 0.75 18.25H16.5C16.9142 18.25 17.25 18.5858 17.25 19C17.25 19.4142 16.9142 19.75 16.5 19.75H0.75C0.335786 19.75 0 19.4142 0 19Z" fill="black"/>
              </svg>
            </button>
            </>           
          )}

          
          { /* TURNOS */ }
          { (section == 'events') && (
            <>
            
            <div className="font-sans ml-4 mt-4 font-bold text-base text-[#1C1243]">Próximos Turnos</div>

            {(patient.next_5_events.length > 0) ? (
              patient.next_5_events.reverse().map((item, index) => (
                <div key={`next_5_events_${index}`} className="font-sans ml-4 mr-4 mt-2 max-w-md mx-auto bg-white rounded-xl shadow-[0_3px_10px_2px_rgba(0,0,0,0.1)] overflow-hidden md:max-w-2xl">
                  <div className="m-4 grid grid-cols-2">

                    <div>
                      <div className="tracking-wide text-sm py-1 text-[#1C1243] font-bold capitalize">
                        {patient.surname}, {patient.name}
                      </div>
                      <div className="mt-2 text-sm text-gray-400">{item.ctype? item.ctype.name : 'Consulta General'}</div>
                    </div>
                    
                    <div className="text-right">
                      { getEventText(item.state_color) }
                      <div className="flex justify-end">
                        <div className="mt-2 ml-1 text-sm font-semibold text-[#1C1243]"><Moment format="DD-MM-YYYY">{item.date_from}</Moment></div>
                        <div className="mt-2 ml-1 text-sm font-semibold text-blue-500"><Moment format="HH:mm">{item.date_from}</Moment>hs</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="font-sans text-center mt-4 text-sm text-gray-500">El paciente no tiene turnos programados.</div>
            )}

            
            <div className="collapse collapse-arrow mt-4">
              <input type="checkbox" className="peer" />
              <div className="font-sans collapse-title font-sans font-bold text-base text-[#1C1243]">Turnos Anteriores</div>
              <div className="collapse-content">
                {(patient.lastest_5_events.length > 0) ? (
                  patient.lastest_5_events.map((item, index) => (
                    <div key={`lastest_5_events_${index}`} className="font-sans mt-2 max-w-md mx-auto bg-white rounded-xl shadow-[0_3px_10px_2px_rgba(0,0,0,0.1)] overflow-hidden md:max-w-2xl">
                      <div className="m-4 grid grid-cols-2">

                        <div>
                          <div className="text-gray-400 tracking-wide text-sm text-[#1C1243] font-bold capitalize">
                            {patient.surname}, {patient.name}
                          </div>
                          <div className="mt-2 text-sm text-gray-400">{item.ctype && item.ctype.name}</div>
                        </div>
                        
                        <div className="text-right">
                          { getEventTextLastest_5(item.state_color) }
                          <div className="flex justify-end">
                            <div className="text-gray-400 mt-2 ml-2 text-sm font-semibold"><Moment format="DD-MM-YYYY">{item.date_from}</Moment></div>
                            <div className="text-gray-400 mt-2 ml-2 text-sm font-semibold"><Moment format="HH:mm">{item.date_from}</Moment>hs</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="font-sans text-center mt-4 text-sm text-gray-500">El paciente no tiene historial de turnos.</div>
                )}
              </div>
            </div>
            </>
          )}
          


          { /* RECETAS */ }
          { (section == 'prescriptions') && (

            <>
            <div className="font-sans ml-4 mr-4 mt-10 bg-white rounded-xl">
              <div className="flex justify-between"> 
                <div className="col-span-6 sm:col-span-3">
                </div>
                <div>
                </div>
              </div>
            </div>

              <div className="font-sans ml-4 mt-4 mb-4 font-bold text-base text-[#1C1243]">Ultima Receta</div>
                {patient.prescriptions.length > 0 ?              
                  <Prescription data={findLastPrescription(patient)} surname={patient.surname} name={patient.name} />
                : 
                <div className="font-sans text-center mt-4 text-sm text-gray-500">El paciente aún no tiene recetas creadas.</div>
                } 
              <div>
              <div className="collapse collapse-arrow">
                <input type="checkbox" className="peer" /> 
                <div className="collapse-title font-sans font-bold text-base text-[#1C1243]">
                  Recetas Anteriores
                </div>
                <div className="collapse-content p-0"> 
                {patient.prescriptions.length > 1 ? patient.prescriptions.slice(1).map((item, idx) => (
                  <Prescription key={`prescr_${idx}`} data={item} surname={patient.surname} name={patient.name} />
                )) : 
                <div className="font-sans text-center mt-4 text-sm text-gray-500">El paciente aún no tiene recetas creadas.</div>
                }
                </div>
              </div>
            </div>
            </>
          )}


          
          { /* HC */ }
          { (section == 'hc') && (

            <>
            <div className="font-sans ml-4 mr-4 mt-10 bg-white rounded-xl">
              <div className="flex justify-between"> 
                <div className="col-span-6 sm:col-span-3">
                </div>
              </div>
            </div>
            <div className="font-sans ml-4 mt-4 font-bold text-base text-[#1C1243]">Historia Clínica</div>

            <MedicalHistory 
                id={id}
                medicalHistoryDetails={medicalHistoryDetails}
                setMedicalHistoryDetails={setMedicalHistoryDetails}
            />
            </>
          )}
        </div>

          <button
            className="absolute top-12 left-6 z-50"
            onClick={() => handleGoBack()}
          >
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.45711 0.292893C7.84763 0.683418 7.84763 1.31658 7.45711 1.70711L3.16421 6L7.45711 10.2929C7.84763 10.6834 7.84763 11.3166 7.45711 11.7071C7.06658 12.0976 6.43342 12.0976 6.04289 11.7071L1.04289 6.70711C0.855357 6.51957 0.75 6.26522 0.75 6C0.75 5.73478 0.855357 5.48043 1.04289 5.29289L6.04289 0.292893C6.43342 -0.0976311 7.06658 -0.0976311 7.45711 0.292893Z" fill="black"/>
            </svg>
          </button>
        </div>
      ) : null}

      {editMode && !medicalHistory ? (
        <div
          className=""
          id=""
        >
          <PatientForm
            handleChange={handleChange}
            handleChangePhone={handleChangePhone}
            validateForm={validateForm}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleDateChange={handleDateChange}
            requiredFields={requiredFields}
            data={{...patientUpdate}}
            button="Guardar"
            title="Editar Paciente"
            editMode={editMode}
            handleCloseEdit={handleCloseEdit}
            cleanPhone={cleanPhone}
            setCleanPhone={setCleanPhone}
            isSubmitting={isSubmitting}
          />
        </div>
      ) : null}

    </>
  );
};

export default InfoPatient;